import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner,
  useBoolean,
} from '@chakra-ui/react';
import { useContext, useEffect } from 'react';

import Button from '@/components/Button';
import { GSAppContext } from '@/components/context/GSAppContext';
import { accentRed } from '@/styles/Colors';

import UpchargeModal from './Modal';

export default function UpchargeBanner({
  openModalOnLoad = false,
}: {
  openModalOnLoad?: boolean;
}) {
  const [isModalOpen, { on: openModal, toggle: toggleModal }] = useBoolean(
    false,
  );
  const {
    broker: { useGetUpchargeConfig },
  } = useContext(GSAppContext);

  const { loading, upchargeConfig } = useGetUpchargeConfig();
  const hasUnpaidCharges = upchargeConfig?.doesUserHaveCharges;

  useEffect(() => {
    if (hasUnpaidCharges && openModalOnLoad) {
      openModal();
    }
  }, [hasUnpaidCharges]);

  if (!loading && !hasUnpaidCharges) return null;

  return (
    <>
      <div className="my-4 md:mx-4">
        {loading && (
          <div className="flex justify-center">
            <Spinner colorScheme="green" />
          </div>
        )}

        {hasUnpaidCharges && (
          <Alert status="error">
            <AlertIcon />
            <div className="md:flex">
              <AlertTitle mr={2}>You have unsettled charges</AlertTitle>
              <AlertDescription>
                <Button
                  variant="link"
                  style={{ color: accentRed }}
                  onClick={toggleModal}>
                  Click here
                </Button>{' '}
                to pay them.
              </AlertDescription>
            </div>
          </Alert>
        )}
      </div>
      <UpchargeModal isOpen={isModalOpen} onClose={toggleModal} />
    </>
  );
}
