import { useState, useCallback, useEffect } from 'react';

type Breakpoint = 'sm' | 'md' | 'lg';

/**
 * Following tailwind's thresholds
 **/
const getWidth = (breakpoint: Breakpoint) => {
  let width;
  if (breakpoint === 'sm') {
    width = '640';
  } else if (breakpoint === 'md') {
    width = '768';
  } else if (breakpoint === 'lg') {
    width = '1024';
  }
  return width;
};

const useMediaQuery = (breakpoint: Breakpoint) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback(e => {
    setTargetReached(!!e.matches);
  }, []);

  const width = getWidth(breakpoint);

  useEffect(() => {
    const media = window.matchMedia(`(min-width: ${width}px)`);
    media.addListener(updateTarget);

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true);
    }

    return () => media.removeListener(updateTarget);
  }, [updateTarget, width]);

  return targetReached;
};

export default useMediaQuery;
