/*
 * Locale-specific functions
 */

/**
 * Pluralize a regular English-language noun
 * This should be corrected to account for -y and -x endings
 */
export const pluralize = (word: string, count: number) => {
  const lastLetter = word.slice(-1);
  const lastTwoLetters = word.slice(-2);
  if (
    lastLetter.includes('z') ||
    lastLetter.includes('s') ||
    lastLetter.includes('x') ||
    lastTwoLetters.includes('ss') ||
    lastTwoLetters.includes('ch') ||
    lastTwoLetters.includes('sh')
  ) {
    return `${word}${count === 1 ? '' : 'es'}`;
  } else {
    return `${word}${count === 1 ? '' : 's'}`;
  }
};

export const asCount = (word: string, count: number) =>
  `${count} ${count > 1 ? pluralize(word, count) : word}`;

export const asMoney = (n = 0, { truncate }: { truncate?: boolean } = {}) =>
  `$${n.toLocaleString(
    undefined, // i.e. local default locale
    {
      minimumFractionDigits: truncate ? 0 : 2,
      maximumFractionDigits: truncate ? 0 : 2,
    },
  )}`;

export const asMoneyOnly = (n = 0, { truncate }: { truncate?: boolean } = {}) =>
  `${n.toLocaleString(
    undefined, // i.e. local default locale
    {
      minimumFractionDigits: truncate ? 0 : 2,
      maximumFractionDigits: truncate ? 0 : 2,
    },
  )}`;
