import { gql } from '@apollo/client';

import graphqlClient from '@/backend/graphql';
import { Query } from 'types';

const GET_BOL_PDF = gql`
  query GetBOL($id: String!) {
    booking(bookingId: $id) {
      bolPdf
    }
  }
`;

export default async function getBOLPdf(bookingId: string) {
  try {
    const { data, errors } = await graphqlClient.query<Query>({
      query: GET_BOL_PDF,
      variables: { id: bookingId },
    });
    if (errors) {
      return { bolPdf: '' };
    }
    const bolPdf = data?.booking?.bolPdf ?? '';
    return { bolPdf };
  } catch {
    return { bolPdf: '' };
  }
}
