import {
  Alert,
  AlertIcon,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useBoolean,
} from '@chakra-ui/react';
import { noop, sum } from 'lodash';
import { useContext, useEffect, useState } from 'react';

import Button from '@/components/Button';
import { GSAppContext } from '@/components/context/GSAppContext';
import getAbsolutePath from '@/utils/getAbsolutePath';
import useMediaQuery from '@/utils/hooks/useMediaQuery';
import { UpchargeDetails } from '@/utils/types';
import { asMoney } from '@/utils/USEnglish';

export default function UpchargeModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const {
    broker: {
      createUpchargeTransaction,
      markUpchargePaid,
      useGetUpchargeDetails,
      useCheckout,
    },
  } = useContext(GSAppContext);
  const { loading, upchargeDetails } = useGetUpchargeDetails();
  const [
    isCompleting,
    { on: setIsCompleting, off: clearIsCompleting },
  ] = useBoolean(false);
  const [isConfirmed, { on: setConfirmed }] = useBoolean(false);
  const [paymentError, setPaymentError] = useState<string | null>(null);
  const isLargerThanMd = useMediaQuery('md');
  const [selectedUpcharges, setSelectedUpCharges] = useState<
    Array<UpchargeDetails['bolId']>
  >([]);
  const [transactionId, setTransactionId] = useState<string | null>(null);

  const selectUpcharge = ({ bolId }: UpchargeDetails) =>
    !selectedUpcharges.includes(bolId) &&
    setSelectedUpCharges([...selectedUpcharges, bolId]);
  const deselectUpcharge = ({ bolId }: UpchargeDetails) =>
    selectedUpcharges.includes(bolId) &&
    setSelectedUpCharges([...selectedUpcharges.filter(u => u !== bolId)]);
  const selectAllUpcharges = () =>
    setSelectedUpCharges(upchargeDetails.map(b => b.bolId));
  const deselectAllUpcharges = () => setSelectedUpCharges([]);

  const runningTotal = selectedUpcharges.length
    ? sum(
        upchargeDetails
          .filter(u => selectedUpcharges.includes(u.bolId))
          .map(u => u.balanceDue),
      )
    : 0;

  // Select all on load
  useEffect(() => {
    if (!loading && upchargeDetails) {
      setSelectedUpCharges(upchargeDetails.map(u => u.bolId));
    }
  }, [loading, upchargeDetails]);

  const { openCheckout, paymentMade } = useCheckout(
    getAbsolutePath(
      'https://www.goship.com/wp-content/uploads/2022/10/logo-dark-ship.png',
    ),
  );

  const onPay = async () => {
    if (selectedUpcharges.length < 1 || runningTotal === 0) return;

    let upchargeId = 'Upcharge -';
    selectedUpcharges.forEach(charge => {
      upchargeId = upchargeId + charge + ',';
    });
    try {
      const paymentInfo = await createUpchargeTransaction({
        cost: runningTotal,
        upchargeId,
      });

      setTransactionId(paymentInfo.id);

      openCheckout(paymentInfo);
    } catch (e) {
      // TODO set payment error
      console.error('an error occurred', e);
    }
  };

  useEffect(() => {
    const markAsPaid = async () => {
      setIsCompleting();
      try {
        const { message } = await markUpchargePaid(
          transactionId,
          upchargeDetails.map(u => ({
            ...u.raw,
            paySelected: selectedUpcharges.includes(u.bolId),
          })),
        );

        if (message?.includes('error')) {
          throw new Error(message);
        }

        setConfirmed();
      } catch (e) {
        console.error(e);
        setPaymentError(
          'There was an error in completing the payment. Your payment was refunded. Please contact support',
        );
      } finally {
        clearIsCompleting();
      }
    };

    if (paymentMade) {
      markAsPaid();
    }
  }, [paymentMade, transactionId]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={isCompleting ? noop : onClose}
        size="full">
        <ModalOverlay />
        <ModalContent className="overflow-auto">
          <ModalCloseButton />
          <ModalHeader>Unpaid charges</ModalHeader>

          {isCompleting || isConfirmed || paymentError ? (
            <>
              <StatusBanner
                isLoading={isCompleting}
                isSuccess={isConfirmed}
                error={paymentError}
                upchargeDetails={upchargeDetails.filter(u =>
                  selectedUpcharges.includes(u.bolId),
                )}
              />
              <div className="my-4 flex justify-center">
                <Button
                  variant="solid"
                  onClick={onClose}
                  isDisabled={isCompleting}>
                  Close
                </Button>
              </div>
            </>
          ) : (
            <>
              <Table
                variant="simple"
                className="text-xs md:text-base"
                size="sm"
                style={{ minHeight: '12rem' }}>
                <Thead>
                  <Tr>
                    <Th>
                      <Checkbox
                        isChecked={
                          selectedUpcharges.length > 0 &&
                          selectedUpcharges.length === upchargeDetails.length
                        }
                        onChange={e =>
                          e.target.checked
                            ? selectAllUpcharges()
                            : deselectAllUpcharges()
                        }
                      />
                    </Th>
                    <Th>BOL</Th>
                    <Th>Pickup</Th>
                    {isLargerThanMd && <Th>Delivery</Th>}
                    <Th>Due</Th>
                    <Th>Reason</Th>
                    {isLargerThanMd && <Th>Original</Th>}
                    {isLargerThanMd && <Th>Updated</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {loading ? (
                    <Tr>
                      <Td
                        colSpan={8}
                        className="h-48"
                        style={{ border: 'none' }}>
                        <div className="flex justify-center items-center">
                          <Spinner colorScheme="green" />
                        </div>
                      </Td>
                    </Tr>
                  ) : (
                    upchargeDetails.map(upcharge => (
                      <Tr key={upcharge.bolId}>
                        <Td>
                          <Checkbox
                            isChecked={selectedUpcharges.includes(
                              upcharge.bolId,
                            )}
                            onChange={e =>
                              e.target.checked
                                ? selectUpcharge(upcharge)
                                : deselectUpcharge(upcharge)
                            }
                          />
                        </Td>
                        <Td>{upcharge.bolId}</Td>
                        <Td>{upcharge.shipDate}</Td>
                        {isLargerThanMd && <Td>{upcharge.deliveryDate}</Td>}
                        <Td>{asMoney(upcharge.balanceDue)}</Td>
                        <Td>{upcharge.reason}</Td>
                        {isLargerThanMd && (
                          <Td>{asMoney(upcharge.originalCost)}</Td>
                        )}
                        {isLargerThanMd && (
                          <Td>{asMoney(upcharge.updatedCost)}</Td>
                        )}
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>

              <div className="text-xl flex justify-end m-4">
                Total {asMoney(runningTotal)}
              </div>

              <div className="m-4">
                Please select the charges you wish to pay.
              </div>

              <div className="my-2 flex justify-center">
                <Button
                  variant="solid"
                  onClick={onPay}
                  isDisabled={isCompleting}>
                  Pay
                </Button>
              </div>
              <div id="balance-checkout" />
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

const StatusBanner = ({
  isLoading,
  isSuccess,
  error,
  upchargeDetails,
}: {
  isLoading: boolean;
  isSuccess: boolean;
  error: string | null;
  upchargeDetails: Array<UpchargeDetails>;
}) => {
  const status = isLoading ? 'info' : isSuccess ? 'success' : 'error';

  if (isLoading) {
    return (
      <Alert status={status}>
        <div>Please wait while we confirm your payment...</div>
        <div className="w-full flex justify-center my-4">
          <Spinner colorScheme="green" />
        </div>
      </Alert>
    );
  }

  if (isSuccess) {
    return (
      <Alert status={status}>
        <AlertIcon />
        <div className="flex flex-col">
          You&apos;ve successfully paid for the following upcharges:
          {upchargeDetails.map(u => (
            <li key={u.bolId}>BOL #{u.bolId}</li>
          ))}
        </div>
      </Alert>
    );
  }

  return (
    <Alert status={status}>
      <AlertIcon />
      {error}
    </Alert>
  );
};
